import { Box, Grid } from "@mui/material";
import React from "react";
import Chatheader from "./Chatheader";
import Webchat from "./Webchat";

const WebMainLayout = () => {

    return (<>
        <Grid container flexDirection={'row'} style={{ height: '100vh' }}>
            <Grid item width={'100%'} height={'100%'} >
                <Box sx={{ mb: .5 }} display={'flex'}>
                    <Chatheader />
                </Box>
                <Box display={'flex'}>
                    <Webchat />
                </Box>
            </Grid>
        </Grid >

    </>)
}
export default WebMainLayout;