import React from 'react';
import { Paper, Grid, Box } from '@mui/material';
import zkchatlogo from '../assets/images/zkchatlogo.svg';

const Chatheader = (props) => {
    return (
        <Grid container width='100%' height={'100%'} component={Paper} mt={2} justifyContent={'flex-start'} alignItems={'center'} pb={2}>
            <Box sx={{ ml: 2 }}>
                <img src={zkchatlogo} />
            </Box>
        </Grid >
    );
}
export default Chatheader;
