import React, { Component } from 'react';
import 'react-chat-widget/lib/styles.css';
import Widgetchat from './widget/Widgetchat';
import WebMainLayout from './webcomponents/WebMainLayout';

export class App extends Component {


  render() {
    return (
      // <Widgetchat />
      <WebMainLayout />
    );
  }
}
