import React from 'react';
import { Avatar, Box, Grid, InputAdornment, Paper, TextField, Typography, css } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import webavt from '../assets/images/webavt.svg';
import robotavt from '../assets/images/robotavt.svg';
import { ReactComponent as MicOff } from '../assets/images/micoff.svg';
import { ReactComponent as MicOn } from '../assets/images/micon.svg';
import axios from 'axios';
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";


//Microsoft cognitive...configs
const subscriptionKey = process.env.REACT_APP_SUB_KEY;
const region = process.env.REACT_APP_REGION;
const sdk = require("microsoft-cognitiveservices-speech-sdk");
var speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, region);
var autoDetectSourceLanguageConfig = sdk.AutoDetectSourceLanguageConfig.fromLanguages(["en-US", "zh-CN"]);
var audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
var recognizer = sdk.SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);
let synthesizer;
const Webchat = (props) => {
    const keyTextLangDetection = process.env.REACT_APP_TEXT_LANG_DETECTION_KEY;
    const endpoint = 'https://zkteco-ls-test.cognitiveservices.azure.com/language/:analyze-text?api-version=2022-05-01';
    const [state, setState] = React.useState({
        message: ''
    });
    const [messages, setMessages] = React.useState([]);
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    }
    const recognitionStop = () => {
        recognizer.stopContinuousRecognitionAsync(
            function () {
                recognizer.close();
                recognizer = undefined;
            },
            function (err) {
                recognizer.close();
                recognizer = undefined;
            });
    }
    const [mic, setMic] = React.useState(true);
    const handleMic = () => {
        setMic(!mic)
        if (mic) {
            speechTotext();
        } else {
            recognitionStop();
        }
    }
    const speechTotext = async () => {
        recognizer = new sdk.SpeechRecognizer(speechConfig);
        try {
            recognizer.recognized = (sender, event) => {
                console.log(`RECOGNIZED LANG: ${JSON.stringify(event.result.privLanguage)}`);
                if (event.result.reason === ResultReason.RecognizedSpeech) {
                    apiCall(event.result.text);
                }
            };
            recognizer.canceled = (sender, event) => {
                console.log(`CANCELED: ${event.reason}`);
                if (event.reason === sdk.CancellationReason.Error) {
                    console.log(`CANCELED: ErrorDetails: ${event.errorDetails}`);
                }
                recognizer.stopKeywordRecognitionAsync();
            };
            await recognizer.startContinuousRecognitionAsync();
        } catch (error) {
            console.error(error);
        }
    }

    const speechHandler = (msg, lang) => {
        var speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, region);
        speechConfig.speechSynthesisLanguage = lang !== undefined ? lang : 'en-US';
        synthesizer = new sdk.SpeechSynthesizer(speechConfig);
        synthesizer.speakTextAsync(msg);

    }

    const stopSpeech = () => {
        if (synthesizer) {
            try {
                synthesizer.stopSpeaking();
                synthesizer.close();
                synthesizer = undefined;
            } catch (error) {
                console.error("Error stopping speech:", error);
            }
        }
    }



    const textLangDetect = (msg) => {
        const headers = {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': keyTextLangDetection
        }
        const payload = {
            "kind": "LanguageDetection",
            "parameters": {
                "modelVersion": "latest"
            },
            "analysisInput": {
                "documents": [
                    {
                        "id": "1",
                        "text": msg
                    }
                ]
            }
        }
        axios.post(endpoint, payload, { headers: headers }).then((res) => {
            if (res.data.results.documents[0].detectedLanguage.iso6391Name === "en") {
                speechHandler(msg, 'en-US')
                return;
            }
            if (res.data.results.documents[0].detectedLanguage.iso6391Name === "zh" || "zh_cht" || "zh_chs") {
                speechHandler(msg, 'zh-CN')
                return;
            }
        });

    }
    const apiCall = async (msg) => {
        const userMessage = { user: msg };
        const updatedMessages = [userMessage, ...messages];
        setMessages(updatedMessages);
        const headers = {
            'Content-Type': 'application/json',
            'api-key': '0b7082c9ec3e4bf787b553595400341b'
        };
        const promt = "Q:" + JSON.stringify(msg) + "?\nA:";
        const payload = {
            "messages": [
                {
                    "role": "user",
                    "content": promt
                }
            ],
            "temperature": 0.7,
            "top_p": 0.95,
            "frequency_penalty": 0,
            "presence_penalty": 0,
            "max_tokens": 800,
            "stop": null
        }
        const response = await axios.post('https://zkteco-openai.openai.azure.com/openai/deployments/test/chat/completions?api-version=2023-03-15-preview', payload,
            { headers: headers });
        stopSpeech();
        const botMessage = response.data.choices[0].message.content;
        const updatedMessagesWithBot = [{ bot: botMessage }, ...updatedMessages];
        setMessages(updatedMessagesWithBot);
        // textLangDetect(botMessage);
    }

    const handleSubmit = async () => {
        setState({ message: '' });
        if (state.message !== '' && state.message !== undefined) {
            apiCall(state.message);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (<>
        <Grid container display={'flex'} justifyContent={'space-between'} height={'100%'}>
            <Paper sx={{ width: '100%', height: '88vh', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }} >
                <Box container sx={{ mb: 10, display: 'flex', flexDirection: 'column-reverse' }} >
                    {messages && messages.length > 0 && (
                        messages.map((data, index) => {
                            { console.log('ooo===', data.bot) }
                            return (<>

                                {data?.user !== '' && data?.user !== undefined && <Box display="flex" justifyContent="flex-end" sx={{ margin: '0px 15px 5px 5px' }}  >
                                    <Typography key={index}
                                        style={{
                                            backgroundColor: '#5EA6CF',
                                            color: '#fff',
                                            padding: '10px',
                                            borderRadius: '12px 0px 12px 12px',
                                            overflow: 'hidden',
                                            wordWrap: 'break-word',
                                            maxWidth: '50%',
                                            marginRight: '5px'
                                        }}>
                                        {data.user}
                                    </Typography>
                                    <Avatar src={webavt} />
                                </Box>}
                                {data?.bot !== '' && data?.bot !== undefined && <Box display="flex" justifyContent="flex-start" sx={{ margin: '0px 0px 5px 15px' }}>
                                    <Avatar src={robotavt} />
                                    <Typography key={index}
                                        style={{
                                            backgroundColor: '#919191',
                                            color: '#fff',
                                            padding: '10px',
                                            borderRadius: '0px 12px 12px 12px',
                                            overflow: 'hidden',
                                            wordWrap: 'break-word',
                                            maxWidth: '50%',
                                            marginLeft: '5px'
                                        }}>
                                        {data.bot}
                                    </Typography>
                                </Box>}

                            </>
                            );
                        })
                    )}
                </Box>

            </Paper>
            <Grid
                display="flex"
                justifyContent="center"
                width="100%"
                sx={{
                    position: 'absolute',
                    bottom: '13px',
                    zIndex: 9999,
                    backgroundColor: '#fff',
                    padding: '0 40px',
                }}
            >
                <TextField
                    placeholder="Type your message here"
                    name="message"
                    fullWidth
                    onChange={handleChange}
                    value={state.message}
                    onKeyDown={handleKeyDown}
                    sx={{
                        backgroundColor: '#F2F2F2',
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: '#fff !important',
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {mic ? (
                                    <MicOff
                                        style={{ marginRight: '20px', cursor: 'pointer', zIndex: 1 }}
                                        onClick={() => handleMic()}
                                    />
                                ) : (
                                    <MicOn
                                        style={{ marginRight: '20px', cursor: 'pointer', zIndex: 1 }}
                                        onClick={() => handleMic()}
                                    />
                                )}
                                <SendIcon
                                    style={{ cursor: 'pointer', zIndex: 1, fontSize: '36px' }}
                                    onClick={() => handleSubmit()}
                                />
                            </InputAdornment>
                        ),
                    }}
                    style={{
                        boxShadow: '0px 0px 5px grey',
                        borderRadius: '5px',
                    }}
                />
            </Grid>
        </Grid >
    </>)
}
export default Webchat;