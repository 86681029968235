import axios from "axios";
import React, { useEffect, useState } from "react";
import { Widget, addResponseMessage, addLinkSnippet, addUserMessage, setQuickButtons, renderCustomComponent } from 'react-chat-widget';
import TittleAvt from '../assets/images/tittleavtar.svg';
import robotavt from '../assets/images/robotavt.svg';
import LuncherImg from '../assets/images/LuncherImg.svg';
import LuncherCloseImg from '../assets/images/LuncherCloseImg.svg';
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Header from "./Header";

//Microsoft cognitive...configs
const subscriptionKey = process.env.REACT_APP_SUB_KEY;
const region = process.env.REACT_APP_REGION;
const sdk = require("microsoft-cognitiveservices-speech-sdk");
var speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, region);
var autoDetectSourceLanguageConfig = sdk.AutoDetectSourceLanguageConfig.fromLanguages(["en-US", "zh-CN"]);
var audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
var recognizer = sdk.SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);

export default function Widgetchat() {
    const keyTextLangDetection = process.env.REACT_APP_TEXT_LANG_DETECTION_KEY;
    const endpoint = 'https://zkteco-ls-test.cognitiveservices.azure.com/language/:analyze-text?api-version=2022-05-01';
    const [open, setOpen] = useState(false);
    const [mic, setMic] = useState(false);
    const links = {
        title: 'My awesome link',
        link: 'https://github.com/Wolox/react-chat-widget',
        target: '_blank'
    };

    const getCustomUI = () => <div>
        <button onClick={() => handlePreMessages('Talk To A Human')} style={{ background: '#fff', border: '2px solid #7ac1436e', borderRadius: '6px', height: '25px', margin: '2px 2px 2px 2px', cursor: 'pointer', color: '#7AC143' }}>Talk To A Human</button>
        <button onClick={() => handlePreMessages('Customer Inquiries')} style={{ background: '#fff', border: '2px solid #7ac1436e', borderRadius: '6px', height: '25px', margin: '2px 2px 2px 2px', cursor: 'pointer', color: '#7AC143' }}>Customer Inquiries</button>
        <button onClick={() => handlePreMessages('Manage Profile')} style={{ background: '#fff', border: '2px solid #7ac1436e', borderRadius: '6px', height: '25px', margin: '2px 2px 2px 2px', cursor: 'pointer', color: '#7AC143' }}>Manage Profile</button>
        <button onClick={() => handlePreMessages('Asset & License Policy')} style={{ background: '#fff', border: '2px solid #7ac1436e', borderRadius: '6px', height: '25px', margin: '2px 2px 2px 2px', cursor: 'pointer', color: '#7AC143' }}>Asset & License Policy</button>
        <button onClick={() => handlePreMessages('Read More About This')} style={{ background: '#fff', border: '2px solid #7ac1436e', borderRadius: '6px', height: '25px', margin: '2px 2px 2px 2px', cursor: 'pointer', color: '#7AC143' }}>Read More About This</button>
        <button onClick={() => handlePreMessages('Talk To A Person')} style={{ background: '#fff', border: '2px solid #7ac1436e', borderRadius: '6px', height: '25px', margin: '2px 2px 2px 2px', cursor: 'pointer', color: '#7AC143' }}>Talk To A Person</button>
        <button onClick={() => handlePreMessages('Check Out Help Center Articles')} style={{ background: '#fff', border: '2px solid #7ac1436e', borderRadius: '6px', height: '25px', margin: '2px 2px 2px 2px', cursor: 'pointer', color: '#7AC143' }}>Check Out Help Center Articles</button>
    </div>;

    const handlePreMessages = (data) => {
        handleNewUserMessage(data, undefined, false);
        addUserMessage(data);
    }

    const buttons = [{ label: '', value: '1' }];
    React.useEffect(() => {
        addResponseMessage('Hi there! Thank you for contacting us. How can I assist?');
        // renderCustomComponent(getCustomUI, links, false)
        setQuickButtons(buttons);
    }, []);

    const getCustomLauncher = (handleToggle) =>
        <img src={!open ? LuncherImg : LuncherCloseImg} onClick={() => { handleToggle(); setOpen(!open) }} style={{ cursor: 'pointer', height: '56px', paddingTop: '2%', paddingLeft: open ? '85%' : '' }} />

    const recognitionStop = () => {
        recognizer.stopContinuousRecognitionAsync(
            function () {
                recognizer.close();
                recognizer = undefined;
            },
            function (err) {
                recognizer.close();
                recognizer = undefined;
            });
    }

    const speechTotext = async () => {
        recognizer = new sdk.SpeechRecognizer(speechConfig);
        try {
            recognizer.recognized = (sender, event) => {
                console.log(`RECOGNIZED LANG: ${JSON.stringify(event.result.privLanguage)}`);
                if (event.result.reason === ResultReason.RecognizedSpeech) {
                    addUserMessage(event.result.text);
                    handleNewUserMessage(event.result.text, event.result.privLanguage, true);
                }
            };
            recognizer.canceled = (sender, event) => {
                console.log(`CANCELED: ${event.reason}`);
                if (event.reason === sdk.CancellationReason.Error) {
                    console.log(`CANCELED: ErrorDetails: ${event.errorDetails}`);
                }
                recognizer.stopKeywordRecognitionAsync();
            };
            await recognizer.startContinuousRecognitionAsync();
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {

        var img2 = document.createElement("img");
        img2.src = mic ? "/micon.svg" : "/mic.svg";
        img2.onclick = () => setMic(!mic);
        img2.style.cursor = "pointer"
        if (true) {
            var elements = Array.from(document.getElementsByClassName("quick-button"));
            document.getElementsByClassName('rcw-send-icon').src = '/attachment.svg';
        }
        if (elements && elements.length > 0) {
            elements[0].replaceWith(img2)
        }
    }, [open])
    useEffect(() => {
        var img2 = document.createElement("img");
        img2.src = mic ? "/micon.svg" : "/mic.svg";
        img2.onclick = () => setMic(!mic);
        img2.style.cursor = "pointer"
        img2.setAttribute('class', 'quick-list-button')

        if (true) {
            var elements = Array.from(document.getElementsByClassName("quick-list-button"));
        }
        if (elements && elements.length > 0) {
            elements[0].replaceWith(img2)
        }
        if (mic) {
            speechTotext();
        } else {
            recognitionStop();
        }
    }, [mic])

    const speechHandler = (msg, lang) => {
        var speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, region);
        speechConfig.speechSynthesisLanguage = lang !== undefined ? lang : 'en-US';
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
        synthesizer.speakTextAsync(msg);
    }
    const textLangDetect = (msg) => {
        const headers = {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': keyTextLangDetection
        }
        const payload = {
            "kind": "LanguageDetection",
            "parameters": {
                "modelVersion": "latest"
            },
            "analysisInput": {
                "documents": [
                    {
                        "id": "1",
                        "text": msg
                    }
                ]
            }
        }
        axios.post(endpoint, payload, { headers: headers }).then((res) => {
            if (res.data.results.documents[0].detectedLanguage.iso6391Name === "en") {
                speechHandler(msg, 'en-US')
                return;
            }
            if (res.data.results.documents[0].detectedLanguage.iso6391Name === "zh" || "zh_cht" || "zh_chs") {
                speechHandler(msg, 'zh-CN')
                return;
            }
        });

    }

    const handleNewUserMessage = async (newMessage, detectedLanguage, speech) => {

        console.log(JSON.stringify(newMessage));
        const headers = {

            'Content-Type': 'application/json',
            'api-key': '0b7082c9ec3e4bf787b553595400341b'
        };
        const promt = "Q:" + JSON.stringify(newMessage) + "?\nA:";
        const payload = {
            "messages": [
                {
                    "role": "user",
                    "content": promt
                }
            ],
            "temperature": 0.7,
            "top_p": 0.95,
            "frequency_penalty": 0,
            "presence_penalty": 0,
            "max_tokens": 800,
            "stop": null
        }
        const response = await axios.post('https://zkteco-openai.openai.azure.com/openai/deployments/test/chat/completions?api-version=2023-03-15-preview', payload,
            { headers: headers });
        console.log('logs...' + response.data.choices[0].message.content);
        addResponseMessage(response.data.choices[0].message.content)
        // if (speech) {
        //     speechHandler(response.data.choices[0].message.content, detectedLanguage);

        // } else {
        //     textLangDetect(response.data.choices[0].message.content);
        // }

        // const res = await axios.get(process.env.REACT_APP_PYTHON_AI_ENDPOINT + newMessage);
        // addResponseMessage(res.data);
        // if (speech) {
        //     speechHandler(res.data, detectedLanguage);
        // } else {
        //     textLangDetect(res.data);
        // }
    };
    const styles = {
        background: {
            backgroundImage: 'url(/bgsmart.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100vw', height: '100vh',
        },
    };
    return (<>
        <Grid container sx={styles.background}>

            <Header />

            <Box sx={{ ml: '90px' }}>
                <Typography variant="h3" gutterBottom style={{ color: '#fff' }} >More Than</Typography>
                <Typography variant="h3" gutterBottom style={{ color: '#fff' }} >Just Another CMS</Typography>
                <Typography style={{ color: '#fff' }}>It's a digital transformation</Typography>
            </Box>

            <Widget
                handleNewUserMessage={handleNewUserMessage}
                profileAvatar={robotavt}
                title="Hello !! How may I help you"
                subtitle=""
                senderPlaceHolder="Type a message..."
                titleAvatar={TittleAvt}
                launcher={handleToggle => getCustomLauncher(handleToggle)}
            />
        </Grid>
    </>)
}